.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.MuiPaper-rounded, .MuiButton-root {
  border-radius: 3px !important;
}

html, body, #root {
  height: 100%;
}

p {
  -webkit-font-smoothing: antialiased;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #AAA;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #AAA;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #AAA;
}

.MuiTypography-h6 {
  font-size: 18px !important;
}
.specialityChipDiv .MuiChip-label {
  padding: 0 !important;
}

.MuiButton-contained.Mui-disabled {
  color: #AAA !important;
}
textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
     -webkit-appearance: none;
     -webkit-border-radius: 0; 
     outline: none !important;
}

.MuiChip-colorPrimary {
  background-color: #FF1C57 !important;
}

.MuiChip-clickableColorPrimary:active {
  background-color: #FF1C57 !important;
}
